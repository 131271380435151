define("discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/industry-focuses", ["exports", "@glimmer/component", "@ember/helper", "@ember/service", "discourse-i18n", "select-kit/components/multi-select", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _service, _discourseI18n, _multiSelect, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MvpUserPreferenceIndustryFocuses extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get industryFocusOPtions() {
      return this.siteSettings.industry_focus_options.split("|");
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="control-group">
          <label class="control-label">
            {{i18n "discourse_uipath_mvp.user_preferences.industry_focus.label"}}
          </label>
          <MultiSelect
            @value={{@user.custom_fields.mvp_industry_focuses}}
            @content={{this.industryFocusOPtions}}
            @nameProperty={{null}}
            @valueProperty={{null}}
            @onChange={{fn (mut @user.custom_fields.mvp_industry_focuses)}}
            @options={{hash maximum=5}}
            class="input-xxlarge mvp-preferences__industry-focus"
          />
          <div class="instructions">
            {{i18n
              "discourse_uipath_mvp.user_preferences.industry_focus.instructions"
            }}
          </div>
        </div>
      
    */
    {
      "id": "KUw2doPO",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"discourse_uipath_mvp.user_preferences.industry_focus.label\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[32,1],[[24,0,\"input-xxlarge mvp-preferences__industry-focus\"]],[[\"@value\",\"@content\",\"@nameProperty\",\"@valueProperty\",\"@onChange\",\"@options\"],[[30,1,[\"custom_fields\",\"mvp_industry_focuses\"]],[30,0,[\"industryFocusOPtions\"]],null,null,[28,[32,2],[[28,[31,0],[[30,1,[\"custom_fields\",\"mvp_industry_focuses\"]]],null]],null],[28,[32,3],null,[[\"maximum\"],[5]]]]],null],[1,\"\\n      \"],[10,0],[14,0,\"instructions\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"discourse_uipath_mvp.user_preferences.industry_focus.instructions\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@user\"],false,[\"mut\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/industry-focuses.js",
      "scope": () => [_discourseI18n.i18n, _multiSelect.default, _helper.fn, _helper.hash],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MvpUserPreferenceIndustryFocuses;
});