define("discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/blog-url", ["exports", "@ember/component", "discourse-i18n", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _discourseI18n, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MvpUserPreferenceBlogUrl = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="control-group">
      <label class="control-label">
        {{i18n "discourse_uipath_mvp.user_preferences.blog_url.label"}}
      </label>
      <Input
        @type="text"
        @value={{@user.custom_fields.mvp_blog_url}}
        class="input-xxlarge mvp-preferences__blog-url"
      />
      <div class="instructions">
        {{i18n "discourse_uipath_mvp.user_preferences.blog_url.instructions"}}
      </div>
    </div>
  
  */
  {
    "id": "rkPgtT6q",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"discourse_uipath_mvp.user_preferences.blog_url.label\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[32,1],[[24,0,\"input-xxlarge mvp-preferences__blog-url\"]],[[\"@type\",\"@value\"],[\"text\",[30,1,[\"custom_fields\",\"mvp_blog_url\"]]]],null],[1,\"\\n    \"],[10,0],[14,0,\"instructions\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"discourse_uipath_mvp.user_preferences.blog_url.instructions\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@user\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/blog-url.js",
    "scope": () => [_discourseI18n.i18n, _component.Input],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "blog-url:MvpUserPreferenceBlogUrl"));
  var _default = _exports.default = MvpUserPreferenceBlogUrl;
});