define("discourse/plugins/discourse-uipath-mvp/discourse/routes/mvps", ["exports", "@ember/service", "discourse/routes/discourse", "discourse-i18n", "discourse/plugins/discourse-uipath-mvp/discourse/models/filter-options"], function (_exports, _service, _discourse, _discourseI18n, _filterOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MvpsRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    beforeModel() {
      if (this.site.mvp_features_restricted && !this.currentUser?.can_see_mvp_features) {
        this.router.replaceWith("/404");
      }
    }
    async model() {
      return {
        availableOptions: await _filterOptions.default.findAll(),
        selectedOptions: this.paramsFor("mvps-index")
      };
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.model = model.availableOptions;
      controller.seedSelectedFilterSet(model.selectedOptions);
    }
    titleToken() {
      return (0, _discourseI18n.i18n)("discourse_uipath_mvp.mvp.title");
    }
  }
  _exports.default = MvpsRoute;
});