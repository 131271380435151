define("discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-social-link", ["exports", "@glimmer/component", "discourse/helpers/d-icon", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dIcon, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ICON_MAP = {
    mvp_x_username: "temporary-twitter-x",
    mvp_linkedin_profile_url: "fab-linkedin",
    mvp_github_username: "fab-github",
    mvp_youtube_channel_url: "fab-youtube",
    mvp_blog_url: "blog"
  };
  const LINK_TEMPLATE_MAP = {
    mvp_x_username: "https://twitter.com/{value}",
    mvp_github_username: "https://github.com/{value}"
  };
  const TITLE_PREFIX_MAP = {
    mvp_x_username: "X",
    mvp_linkedin_profile_url: "LinkedIn",
    mvp_github_username: "GitHub",
    mvp_youtube_channel_url: "YouTube",
    mvp_blog_url: "Blog"
  };
  class MvpSocialLink extends _component.default {
    get iconName() {
      return ICON_MAP[this.args.name];
    }
    get href() {
      const template = LINK_TEMPLATE_MAP[this.args.name];
      if (template) {
        return template.replace("{value}", this.args.value);
      }
      // TODO: This isn't comprehensive, some invalid urls will slip through
      //      Might be great to validate this on creation instead
      //      Investigate validations for user custom  fields
      const schemeRegex = /^https?:\/\//i;
      const url = schemeRegex.test(this.args.value) ? this.args.value : `http://${this.args.value}`;
      try {
        const parsedUrl = new URL(url);
        return parsedUrl.toString();
      } catch {
        return null;
      }
    }
    get title() {
      return `${TITLE_PREFIX_MAP[this.args.name]} | ${this.href}`;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.href}}
          <div class="mvp-social-link">
            <a
              target="_blank"
              title={{this.title}}
              rel="noopener noreferrer"
              href={{this.href}}
            >
              {{dIcon this.iconName}}
            </a>
          </div>
        {{/if}}
      
    */
    {
      "id": "P71cs4B5",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"href\"]],[[[1,\"      \"],[10,0],[14,0,\"mvp-social-link\"],[12],[1,\"\\n        \"],[10,3],[14,\"target\",\"_blank\"],[15,\"title\",[30,0,[\"title\"]]],[14,\"rel\",\"noopener noreferrer\"],[15,6,[30,0,[\"href\"]]],[12],[1,\"\\n          \"],[1,[28,[32,0],[[30,0,[\"iconName\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-social-link.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MvpSocialLink;
});