define("discourse/plugins/discourse-uipath-mvp/discourse/models/filter-options", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/get-owner", "discourse-i18n", "discourse/plugins/discourse-uipath-mvp/discourse/lib/country-options", "discourse/plugins/discourse-uipath-mvp/discourse/lib/language-options"], function (_exports, _ajax, _ajaxError, _getOwner, _discourseI18n, _countryOptions, _languageOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FilterOptions {
    static async findAll() {
      let availableOptions;
      const siteSettings = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:site-settings");
      try {
        availableOptions = await (0, _ajax.ajax)("/mvps/filter-options.json");
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
        availableOptions = {};
      }
      return [{
        name: (0, _discourseI18n.i18n)("discourse_uipath_mvp.filters.program"),
        paramKey: "programs",
        options: siteSettings.mvp_programs.split("|")
      }, {
        name: (0, _discourseI18n.i18n)("discourse_uipath_mvp.filters.category"),
        paramKey: "categories",
        options: siteSettings.mvp_award_categories.split("|")
      }, {
        name: (0, _discourseI18n.i18n)("discourse_uipath_mvp.filters.year"),
        paramKey: "years",
        options: siteSettings.mvp_award_years.split("|")
      }, {
        name: (0, _discourseI18n.i18n)("discourse_uipath_mvp.filters.language"),
        paramKey: "languages",
        options: _languageOptions.default.filter(opt => availableOptions.languages?.includes(opt.value))
      }, {
        name: (0, _discourseI18n.i18n)("discourse_uipath_mvp.filters.region"),
        paramKey: "regions",
        options: [{
          value: "emea",
          label: (0, _discourseI18n.i18n)("discourse_uipath_mvp.regions.emea")
        }, {
          value: "americas",
          label: (0, _discourseI18n.i18n)("discourse_uipath_mvp.regions.americas")
        }, {
          value: "apac",
          label: (0, _discourseI18n.i18n)("discourse_uipath_mvp.regions.apac")
        }]
      }, {
        name: (0, _discourseI18n.i18n)("discourse_uipath_mvp.filters.country"),
        paramKey: "countries",
        options: _countryOptions.default.filter(opt => availableOptions.countries?.includes(opt.value))
      }, {
        name: (0, _discourseI18n.i18n)("discourse_uipath_mvp.filters.technology_expertise"),
        paramKey: "technology_expertise",
        options: siteSettings.technology_expertise_options.split("|").filter(opt => availableOptions.technology_expertise?.includes(opt))
      }, {
        name: (0, _discourseI18n.i18n)("discourse_uipath_mvp.filters.industry_focus"),
        paramKey: "industry_focus",
        options: siteSettings.industry_focus_options.split("|").filter(opt => availableOptions.industry_focus?.includes(opt))
      }];
    }
  }
  _exports.default = FilterOptions;
});