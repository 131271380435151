define("discourse/plugins/discourse-uipath-mvp/discourse/components/create-high-impact-activity-button", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "@ember/utils", "discourse/components/d-button", "discourse/lib/ajax", "discourse/models/form-template", "discourse-i18n", "float-kit/components/d-menu", "discourse/plugins/discourse-uipath-mvp/discourse/helpers/titleize", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _didInsert, _service, _utils, _dButton, _ajax, _formTemplate, _discourseI18n, _dMenu, _titleize, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CreateHighImpactActivityButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "availableFormTemplates", [_tracking.tracked]))();
    #availableFormTemplates = (() => (dt7948.i(this, "availableFormTemplates"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "availableTags", [_tracking.tracked]))();
    #availableTags = (() => (dt7948.i(this, "availableTags"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "isLoadingOptions", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoadingOptions = (() => (dt7948.i(this, "isLoadingOptions"), void 0))();
    get isDisabled() {
      return !this.isLoadingOptions && (0, _utils.isBlank)(this.availableTags);
    }
    async loadOptions() {
      this.isLoadingOptions = true;
      const allowedTagGroups = this.args.category.allowed_tag_groups;
      if (allowedTagGroups.length > 0) {
        const {
          results
        } = await (0, _ajax.ajax)(`/tag_groups/filter/search`, {
          data: {
            names: allowedTagGroups
          }
        });
        const tags = results.flatMap(tagGroup => tagGroup.tag_names);
        const formTemplates = await _formTemplate.default.findAll();
        this.availableFormTemplates = formTemplates.filter(template => this.args.category.form_template_ids.includes(template.id));
        this.availableTags = tags.filter(tag => {
          return this.availableFormTemplates.find(template => (0, _titleize.default)(template.name) === (0, _titleize.default)(tag));
        });
      }
      this.isLoadingOptions = false;
    }
    static #_6 = (() => dt7948.n(this.prototype, "loadOptions", [_object.action]))();
    async openComposer(tag, closeMenu) {
      closeMenu();
      if (!this.availableFormTemplates) {
        return;
      }
      const formTemplate = this.availableFormTemplates.find(template => (0, _titleize.default)(template.name) === (0, _titleize.default)(tag));
      if (formTemplate) {
        this.composer.openNewTopic({
          category: this.args.category,
          tags: [tag],
          formTemplate
        });
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "openComposer", [_object.action]))();
    static #_8 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DMenu
          @icon={{@icon}}
          @disabled={{this.isDisabled}}
          @label={{I18n.t
            "discourse_uipath_mvp.user_profile_controls.new_activity"
          }}
          class="mvp-high-impact-activity-button"
          id="custom-create-topic"
          {{didInsert this.loadOptions}}
        >
          <:content as |menu|>
            <div class="mvp-high-impact-activity-options">
              {{#each this.availableTags as |tag|}}
                <DButton
                  @translatedTitle={{titleize tag}}
                  @translatedLabel={{titleize tag}}
                  @action={{fn this.openComposer tag menu.close}}
                  class="btn-flat mvp-high-impact-activity-options__item"
                />
              {{/each}}
            </div>
          </:content>
        </DMenu>
      
    */
    {
      "id": "UMf3gJ2y",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"mvp-high-impact-activity-button\"],[24,1,\"custom-create-topic\"],[4,[32,2],[[30,0,[\"loadOptions\"]]],null]],[[\"@icon\",\"@disabled\",\"@label\"],[[30,1],[30,0,[\"isDisabled\"]],[28,[32,1,[\"t\"]],[\"discourse_uipath_mvp.user_profile_controls.new_activity\"],null]]],[[\"content\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"mvp-high-impact-activity-options\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"availableTags\"]]],null]],null],null,[[[1,\"            \"],[8,[32,3],[[24,0,\"btn-flat mvp-high-impact-activity-options__item\"]],[[\"@translatedTitle\",\"@translatedLabel\",\"@action\"],[[28,[32,4],[[30,3]],null],[28,[32,4],[[30,3]],null],[28,[32,5],[[30,0,[\"openComposer\"]],[30,3],[30,2,[\"close\"]]],null]]],null],[1,\"\\n\"]],[3]],null],[1,\"        \"],[13],[1,\"\\n      \"]],[2]]]]],[1,\"\\n  \"]],[\"@icon\",\"menu\",\"tag\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-uipath-mvp/discourse/components/create-high-impact-activity-button.js",
      "scope": () => [_dMenu.default, _discourseI18n.default, _didInsert.default, _dButton.default, _titleize.default, _helper.fn],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CreateHighImpactActivityButton;
});