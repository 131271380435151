define("discourse/plugins/discourse-uipath-mvp/discourse/pre-initializers/user-location", ["exports", "discourse/lib/plugin-api", "discourse-i18n"], function (_exports, _pluginApi, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "user-location",
    before: "inject-discourse-objects",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.24.0", api => {
        api.modifyClass("model:user", {
          pluginId: "discourse-uipath-mvp",
          originalLocation: null,
          returnOriginalLocation: false,
          // UiPath has a `mvp_country_code` user custom field for a user's country of residence.
          // While we want this user custom field to be stored separately from core's UserProfile location in
          // the backend, we want a user's location in the UI to be displayed as `<Country Name>, <User Profile Location>`
          get location() {
            const countryCode = this.custom_fields?.mvp_country_code;
            if (this.returnOriginalLocation || !countryCode) {
              return this.originalLocation;
            }
            const country = (0, _discourseI18n.i18n)(`discourse_uipath_mvp.countries.${countryCode}`);
            if (!this.originalLocation) {
              return country;
            }
            return `${country}, ${this.originalLocation}`;
          },
          set location(value) {
            this.originalLocation = value;
          }
        });
      });
    }
  };
});